
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CutStatusMetrics } from '@/models/deviceStatusMetrics';
import { getStateCss } from '@/utils/color';

@Component
export default class CutStatus extends Vue {
  @Prop({ required: true })
  private data!: CutStatusMetrics;

  private get stateStyle(): string {
    return this.data?.last_state ? getStateCss(this.data.last_state) : '';
  }
}
