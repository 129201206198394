import { render, staticRenderFns } from "./CutStatus.vue?vue&type=template&id=fa6f2880&scoped=true"
import script from "./CutStatus.vue?vue&type=script&lang=ts"
export * from "./CutStatus.vue?vue&type=script&lang=ts"
import style0 from "./CutStatus.vue?vue&type=style&index=0&id=fa6f2880&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa6f2880",
  null
  
)

export default component.exports